.uploadhistoryTable td,
th {
  text-align: left;
  padding: 8px;
  border: 1px solid #dddddd;
  font-size: 14px;
}
.uploadhistoryTable tr:nth-child(even) {
  background-color: #f9f9f9;
}
.uploadhistoryTable {
  width: 100%;
  margin-top: 30px;
}
.cancel-button {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  border: none;
  outline: none;
  padding: 5px 15px;
  border-radius: 5px;
}
