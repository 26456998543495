.map-college-data-container {
  width: 49%;
}
.goals-required-course-set-container {
  display: flex;
  justify-content: space-between;
}
.goals-required-course {
  width: 49%;
}
.danger-button {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 8px;
}
.danger-button:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
  border: none;
  outline: none;
  border-radius: 5px;
  padding: 5px 8px;
}
