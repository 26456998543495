.counselor-tabs-container{
    display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 5px;
}
.manage-group-list-button{
    height: 40px;
    background-color: var(--selected-primary-button-color);
    color: white;
}

@media screen and (max-width :769px ) {
    .counselor-tabs-container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
}