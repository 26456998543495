.single_msg_container {
  width: 390px;
  height: 140px;
  background: white;
  align-self: center;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 10px;
}
.date_delete_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.date {
  color: var(--message-time-color);
  font-size: 12px;
  margin-bottom: 0px;
}
.active_selectedMsg {
  border: 2px solid var(--text-color);
  background: #fdf8f4 !important;
}

@media screen and (max-width: 769px) {
  .single_msg_container {
    width: 100%;
  }
}
