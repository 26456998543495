.school-queries-container{
    background: white;
    padding: 20px 30px 90px 20px;
    border: 2px solid var(--secondary-color);
}
.query-button-active{
    background: var(--selected-primary-button-color);
    color: white;
    font-size: 9px;
    font-weight: bold;
    height: 40px;
    width: 90px;
}
.query-button-inactive{
    width: 90px;
    background: white;
    color: var(--selected-primary-button-color);
    font-size: 9px;
    font-weight: bold;
    border: 2px solid var(--selected-primary-button-color);
    height: 40px;
}