.shade {
  border: 2px solid darkgray;
  background-color: darkgray;
  padding: 10px 20px 10px 10px;
  cursor: pointer;
  width: 70%;
}

.inner {
  width: 70%;
  border: 1px solid darkgray;
  padding: 5px;
  background-color: white;
}

.box {
  padding: 10px;
}

.input-border {
  border: 1px solid black;
  border-radius: 3px;
  padding: 3px;
  width: 50%;
}

.star-mendatory {
  color: red;
}
.add-button {
  margin-top: 10px;
  background-color: orange;
  color: white;
  border: 1px solid orange;
  border-radius: 3px;
  padding: 5px;
}
.save-button {
  margin-top: 10px;
  background-color: green;
  color: white;
  border: 1px solid green;
  border-radius: 3px;
  padding: 5px;
}
.save-button:disabled {
  opacity: 0.6;
}
.cancel-button-config {
  margin-top: 10px;
  background-color: #d9534f;
  color: white;
  border: 1px solid #d9534f;
  border-radius: 3px;
  padding: 5px;
}

.admin-config-icon {
  cursor: pointer;
  float: right;
}
