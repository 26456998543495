.modal-button-active{
    color: white;
    height:40px;
    width:175px;
    background: #2c121b;
}
.modal-button-inactive{
    color: #2c121b;
    height:40px;
    width:175px;
    background: white;
}