.studentAccountWrap {
    height: 100vh;

    .logoImg {
        margin-top: 45px;
        margin-bottom: 40px;
        width: 180px;
    }

    .rememberText {
        padding-bottom: 10px;
        font-weight: var(--font-medium);
        font-size: var(--font14);
        line-height: 1.2;
        letter-spacing: -0.2px;
        color: var(--color7);
    }

    .orContainer {
        display: flex;
        align-items: center;
      }
      
      .line {
        flex: 1;
        height: 1px;
        background-color: black;
      }
      
      p {
        margin: 0 10px;
      }

      .googleButton {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        color: black;
        border: 1px solid black;
        border-radius: 4px;
        padding: 10px 60px 10px 60px;
        cursor: pointer;
      }
      .buttonShape {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .googleLogo {
        width: 24px; 
        height: 24px;
        margin-right: 10px;
      }
      

    .resendBtn {
        font-size: var(--font16);
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .studentAccountInner {
        background: url('https://siembra-frontend.s3.us-west-1.amazonaws.com/images/forgot-pswd-bg.png') no-repeat center;
        background-size: cover;
        // height: 395px;
        height: 48vh;
    }

    .studentAccountBlockInner {
        .boxWrap {
            max-width: 745px;
            margin-left: auto;
            margin-right: auto;
        }

        .boxwrapInner {
            max-width: 745px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            padding: 45px 50px 32px 50px;

            @media(max-width : 767px) {
                padding: 45px 20px 32px 20px;
            }

            background: var(--white-color);
            border: 1px solid rgba(138, 165, 182, 0.2);
            box-shadow: 0px 8px 8px rgba(141, 161, 172, 0.15);
            border-radius: 15px;

            .accountTitle {
                max-width: 450px;
                margin: 0 auto;
                margin-bottom: 20px;
                font-weight: var(--font-bold);
                font-size: var(--font32);
                line-height: 1.2;
                letter-spacing: -0.3px;
                color: var(--color6);

            }
        }
    }

    .hintText {
        font-style: italic;
        font-weight: var(--font-normal);
        font-size: var(--font14);
        line-height: 1.2;
        color: var(--color13);
        margin-bottom: 0;
        margin-top: 3px;
    }

    .btnWrap {
        @media(max-width: 767px) {
            padding: 0 15px;
        }

    }

    .btnWrapper {
        display: flex;
        margin: 0 -15px;
        padding-bottom: 20px;

        @media(max-width: 767px) {
            flex-direction: column;
        }

        .btnInner {
            flex: 1;
            padding: 0 15px;
        }

        .stdFormBtn {
            margin-bottom: 12px;

            @media(max-width: 767px) {
                flex-direction: column;
            }
        }
    }


}