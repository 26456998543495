.query-result-container {
  background: var(--background-color);
  padding: 20px;
  height: 100%;
  border: 2px solid var(--message-time-color);
  overflow-y: scroll;
}
.inner-query-result-container {
  border: 1px solid var(--message-time-color);
  padding-bottom: 20px;
  background: white;
  height: inherit;
  position: relative;
}
.view-result {
  padding: 37px 15px 15px 15px;
  margin-left: 0px;
  max-width: 1210px;
}
.container {
  justify-content: space-between;
}
.query-result-pagination {
  position: inherit;
  /* bottom: 20; */
  display: flex;
  justify-content: end;
  margin-left: auto;
  padding: 18px 0px;
  /* right: 35px; */
}
.contact-button-container {
  display: flex;
  flex-direction: column;
}
.contact-button {
  background: var(--selected-primary-button-color);
  color: white;
  height: 40px;
  padding: 10px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: initial;
  margin: 5px 0px;
}
.super-app-theme--header {
  font-size: 14px;
  font-weight: bold;
  color: var(--selected-primary-button-color);
}
.user-icon {
  color: white;
  margin-right: 5px;
}
.table-container-less {
  width: 100%;
  height: calc(100vh - 286px);
}
.table-container {
  width: 100%;
  height: calc(100vh - 315px);
}
.result-table {
  height: 100%;
}
.result-button-container {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  grid-gap: 15px;
  justify-content: space-around;
  align-items: center;
}
.premium-close {
  justify-content: space-between;
  float: right;
  margin: 0px 10px 10px 0px;
  color: white;
  height:40px;
  width:175px;
  background: #2c121b;
}

@media screen and (max-width: 769px) {
  .query-result-pagination {
    position: absolute;
    bottom: auto;
    right: 0;
  }
}
