.requiredname-input-container {
  display: grid;
  grid-template-columns: 200px 600px;
  gap: 30px;
  margin: 10px 0;
}
.requirement-text {
  font-size: 14px;
  font-weight: 700;
  padding: 5px;
}
.require-input-name {
  border: 1px solid #ccc;
  border-color: #ccc;
}
.require-input-name:focus {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  border-color: #66afe9;
  border: 1px solid #66afe9;
  outline: none;
}
