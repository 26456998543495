.calendar-add-icon{
    opacity: 0.2;
}
.calendar-add-icon:hover{
    opacity: 1;
}
.add-new-event{
    color: #fff;
    border-color: green;
    background-color: #090;
    border: none;
    outline: none;
    padding:5px 10px;
    border-radius: 5px;
    margin-top: 15px;
    position: absolute;
    right: 15px;
    top: -10px;
}