.save-modal-button {
  background-color: #090;
  border: none;
  outline: none;
  color: white;
  padding: 5px 15px;
  border-radius: 3px;
}
.save-modal-button:hover {
  background-color: #286090;
}

.disabled-modal-button {
  background-color: #286090;
  border: none;
  outline: none;
  color: white;
  padding: 5px 15px;
  border-radius: 3px;
  opacity: 0.65;
}
.cancel-modal-button {
  background-color: #d9534f;
  border: none;
  outline: none;
  color: white;
  padding: 5px 15px;
  border-radius: 3px;
}
.cancel-modal-button2{
  background:#ddd;
  color: black;
  padding: 5px 15px;
  margin-left: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
}
.cancel-modal-button:hover {
  background-color: #c9302c;
}
