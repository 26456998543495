.form-group {
    position: relative;
    margin-bottom: 1.5rem;
    margin-top: 25px;
}
.labelText {
    padding: 5px 0 0 15px;
    font-size: 10px;
    transition: all 200ms;
    opacity: 0.7;
    font-weight: bold;
    color: #b0b0b0;
    position: absolute;
    left: 0;
}
.form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 20px 0 0 13px;
    left: 0;
    opacity: 0.7;
    color: var(--selected-primary-button-color);
    font-size: 16px;
    font-weight: 700;
}
input:focus-visible + .form-control-placeholder {
    padding: 5px 0 0 15px;
    font-size: 10px;
    transition: all 200ms;
    opacity: 0.7;
    font-weight: bold;
    color: #b0b0b0;
}
.student-input-name{
    width: 85%;
    background-color: #fff;
    padding: 30px 12px;
    border: 1px solid #b7b6b6;
    color: grey;
    border-radius: 1px;
    font-size: 16px;
    height: 40px;
    text-decoration: none;
    outline: 0;
}
@media screen and (max-width:769px){
    .student-input-name{
        width:100%
    }
}