.registration_container{
    background-color: var(--background-color);
    height:100vh;
    overflow-y: scroll;
}
.registration_label{
    top: 5px;
    font-size: 12px;
    color: var(--input-label-color);
    opacity: .6;
    position: absolute;
    left: 0;
    padding: 2px 10px 0 10px;
    font-weight: 700;
}
.update-button {
    background-color: rgb(0,128,0);
    border: rgb(0,128,0);
    border-radius: 5px;
    color: #FFFF;
    width: 150px;
    padding: 10px;
}
.update-button:hover{
    background-color: rgb(0, 123, 255)
}
.registration_input{
    width: 100%;
    background-color: #FFF;
    padding: 30px 12px;
    border: 1px solid var(--border-color);
    color: grey;
    border-radius: 1px;
    font-size: 16px;
    height: 40px;
    text-decoration: none;
    outline: 0;
}
.registration_input:focus{
    box-shadow: inset 0 0px 0px #000008, 0 0 6px #66AFE9;
}
.registration_button_container{
    display: flex;
    justify-content: space-between;
}
.registration_next_button{
    width: 100%!important;
    background-color:var(--selected-primary-button-color);
    border-color: var(--selected-primary-button-color);
    height: 40px;
    border-radius: 0;
    color: var(--sign-in-text-color);
}
.registration_next_button :active{
    outline: none;
    border: none;
}
.registration_next_button :focus{
    outline:none;
    border: none;
}
.registration_next_button :focus-visible{
    outline:none;
    border: none;
}
.registration_next_button:focus-within{
    outline:none;
    border: none;
}
.buttonRingNext{
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
}
.buttonRingCancel{
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
.registration_cancel_button{
    width:100%;
    background-color: var(--sign-in-text-color);
    border-color: var(--selected-primary-button-color);
    height: 40px;
    border-radius: 0;
    color: var(--selected-primary-button-color);  
}

@media screen and (max-width:769px){
    .registration_label{
        margin-left: 10px;
    }
}

.login_container {
    background: var(--background-color);
    height: 100%;
    overflow-y: scroll;
  }
  .login_container::-webkit-scrollbar {
    display: none;
  }
  .logo {
    width: 600px;
    height: 242px;
    margin-top: 65px;
    text-align: center;
    @media screen and (max-width: 769px) {
      width: 115px;
      height: auto;
    }
  }
  .text {
    text-align: center;
    /* margin-top: 50px; */
    color: var(--selected-primary-button-color);
    font-size: 15px;
    font-weight: 600;
  }
  
  .form_container {
    margin-left: 30%;
    margin-top: 5px;
    @media screen and (max-width: 769px) {
      margin-left: 0px;
      padding: 0 30px;
    }
  }
  .input {
    width: 100%;
    background-color: #fff;
    padding: 30px 12px;
    border: 1px solid #b7b6b6;
    color: grey;
    border-radius: 1px;
    font-size: 16px;
    height: 40px;
    text-decoration: none;
    outline: 0;
  }
  .input:focus {
    box-shadow: inset 0 0px 0px #000008, 0 0 6px #66afe9;
  }
  .forget_password {
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--selected-primary-button-color);
    text-decoration: underline;
    font-size: 14px;
  }
  .form-group {
    position: relative;
    margin-bottom: 1.5rem;
    margin-top: 25px;
  }
  .sign_in {
    width: 100%;
    background-color: var(--selected-primary-button-color);
    border-color: var(--selected-primary-button-color);
    border-radius: 1px;
    height: 46px;
    color: var(--sign-in-text-color);
    font-size: 16px;
    font-weight: 700;
  }
  .form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 20px 0 0 13px;
    left: 0;
    opacity: 0.7;
    color: var(--selected-primary-button-color);
    font-size: 16px;
    font-weight: 700;
  }
  .labelText {
    padding: 5px 0 0 15px;
    font-size: 10px;
    transition: all 200ms;
    opacity: 0.7;
    font-weight: bold;
    color: #b0b0b0;
    position: absolute;
    left: 0;
  }
  input:focus-visible + .form-control-placeholder {
    padding: 5px 0 0 15px;
    font-size: 10px;
    transition: all 200ms;
    opacity: 0.7;
    font-weight: bold;
    color: #b0b0b0;
  }
  .account_text {
    color: var(--selected-primary-button-color);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
    text-align: center;
  }
  .create_account_text {
    text-align: center;
    color: var(--selected-primary-button-color);
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
  }
  
  /**Footer css */
  .footer_container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-top: 160px;
    padding: 0 30px;
    justify-content: center;
  }
  .footer_siembra_inc {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    color: #000;
    padding: 0 10px;
  }
  .footer_siembra_inc:hover{
      text-decoration: underline;
  }
  .footer_privacy {
    font-size: 14px;
    cursor: pointer;
    color: #000;
    text-decoration: underline;
    padding: 0 10px;
  }
  
  .login_error {
    font-size: 14px;
    color: #6f6a6a;
    padding-left: 10px;
  }
  
  @media screen and (max-width: 769px) {
    .logo {
      width: auto;
      height: 115px;
    }
    .form_container {
      margin-left: 0px !important;
      padding: 0 30px;
    }
    .text {
      width: 100%;
      margin: auto;
      /* margin-top: 50px; */
    }
    .footer_container {
      padding: 0px;
    }
  }
.TitleContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--title-text-color);
    padding: 0 15px 0 0;
    background-color: var(--background-color);
    border-left:1px solid var(--title-text-color);
    border-right:1px solid var(--title-text-color);
    border-top:1px solid var(--title-text-color)
}
.titleText{
    color: var(--selected-primary-button-color);
    font-size: 18px;
    font-weight: 700;
    padding: 15px;
    margin-bottom: 0px;
}
@media screen and (max-width:769px){
    .titleText{
        border-bottom: 0;
        border-left: 0;
    }
}

.profile_container{
    padding:10px;
}
.profile_personal_information{
    margin-top: 10px;
    width: 100%;
    padding: 10px;
}
.personal_information_title{
    font-weight: 700;
    font-size: 21px;
    color: var(--title-text-color);
    margin-bottom: 0;
}
.profile_input_container{
    position: relative;
    margin-top: 15px;
}
.profile_input{
    width: 100%;
    background-color: #FFF;
    padding: 20px 15px 5px 8px;
    border: 1px solid var(--background-color);
    color: var(--title-text-color);
    border-radius: 1px;
    font-size: 16px;
    height: 50px;
    text-decoration: none;
    outline: 0;
}
.profile_contact_information{
    width: 100%;
    padding: 15px;
}
.email_container{
    margin-top: 25px;
    padding-top: 25px;
    margin-right: 0 !important;
}
.recovery_email{
    margin-top: 0;
}
.profile_button_container{
    width: 100%;
    display: flex;
}
.success_alert{
    color:#3c763d;
    background-color:rgb(60,118,61, 0.5);
    padding:16px;
}
.error_alert{
  color:#ff3333;
  background-color:rgb(255,51,51, 0.5);
  padding:16px;
}
.notification_alert{
  color:#FFF;
  background-color:#808080;
  padding:16px;
}
.show_error{
    padding:5px 0 0 8px;
    color: red;
}

@media screen and (max-width:769px){
 .profile_button_container{
     padding : 0 22px 0 0;
     width:100%
 }
 .profile_button{
     margin-bottom:10px
 }
 .recovery_email{
     margin-top:10px
 }
 .email_container{
     margin-top: 0px;
 }
 .profile_input_container{
    padding: 0px 10px 0 10px;
 }
 .email-msg-container{
     margin-left: 10px;
 }
 .show_error{
     padding: 3px 20px;
 }
}