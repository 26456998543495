.main_wrapper {
  /* width: calc(100vw - 283px);
    position:absolute; 
    left: 283px;
    height: auto;
    overflow-y: scroll; */
  /* grid-template-rows: 0fr 1fr 0fr; */
  /* 100% width, but static widths for content and sidebars */
  /* grid-template-columns: 283px 900px; */
  /* Force grid to be at least the height of the screen */
  min-height: 100vh;
  width: calc(100vw - 283px);
}
.consistentHeight {
  /*flex: 1;*/
}
.scrollBar_hidden {
  /* overflow-y: scroll; */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollBar_hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollBar_hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main_container {
  background-color: #ede7df;
  width: 100%;
}
.active-tab {
  height: 46px;
  line-height: 46px;
  background-color: var(--secondary-color);
  color: white;
  font-size: 14px;
  width: 180px;
  text-align: center;
  font-weight: bold;
  margin: 0 2px;
  border: none;
  outline: none;
}
.inactive-tab {
  height: 46px;
  line-height: 46px;
  background-color: var(--selected-primary-button-color);
  color: #a68792;
  font-size: 14px;
  width: 180px;
  text-align: center;
  font-weight: bold;
  margin: 0 2px;
  border: none;
  outline: none;
}
.inactive-opacity {
  opacity: 0.5;
}
.separator {
  border-left: 1px solid var(--text-color);
  height: inherit;
}
.msglist_container {
  border-top: 2px solid var(--text-color);
  margin: 50px 0px 15px 15px;
  background-color: var(--background-color);
  width: 420px;
  display: flex;
  flex-direction: column;
}
.form-group {
  position: relative;
  margin: 1.5rem 0;
}
.search-student {
  width: 100%;
  display: flex;
  align-items: center;
}
.tab-grid{
  display: flex;
}
.student1Msg{
  margin: 15px;
  display: flex;
  padding: 20px 45px;
  border-bottom: 2px solid var(--text-color);
}
.registration_input {
  width: 100%;
  background-color: #fff;
  padding: 24px;
  border: 1px solid var(--border-color);
  color: grey;
  border-radius: 1px;
  font-size: 16px;
  height: 40px;
  text-decoration: none;
  outline: 0;
}
.registration_input:focus {
  box-shadow: inset 0 0px 0px #000008, 0 0 6px #66afe9;
}
.single-message-container {
  width: 100%;
  height: 100%;
}
.loading {
  width: 100%;
  height: calc(100vh - 360px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.counselor-msg-container {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
}

.msg-container {
  display: grid;
  /*  */
}
.msg-detail-container {
  grid-template-columns: repeat(5, 1fr);
}
.reply-container-mobile {
  display: flex;
}
@media screen and (max-width: 769px) {
  .msg-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .msglist_container {
    margin: 0px;
    width: 100%;
  }
  .counselor-msg-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 10px;
  }
  .reply-container-gap {
    padding: 5px;
  }
  .student-summary-container {
    margin: auto;
  }
  .reply-container-mobile {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .tab-grid{
      display: grid;
      grid-template-columns:auto;
      grid-gap: 5px;
  }
  .student1Msg{
    margin: 0px;
    display: grid;
    padding: 20px 45px;
    border-bottom: 2px solid var(--text-color);
  }
}