.start-time-display {
  background-color: white;
  padding: 5px 20px;
}
.calendar-message-modal {
  border: 1px solid #ccc;
  margin: 0px 15px;
  cursor: pointer;
}
.calendar-message-modal :hover {
  background-color: #edefd7;
}
.create-event-button {
  border-color: green;
  background-color: #090;
  color: white;
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 5px;
}
.create-calendar-event {
  font-size: 14px;
  color: #333;
  background: #efefef;
  height: 100%;
  width: 600px;
  padding: 25px;
}

.date-timepicker {
  height: 50px;
}

@media screen and (max-width: 769px) {
  .create-calendar-event {
    width: 100%;
  }
}
