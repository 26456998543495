.template-grid-container{
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 40px;
}
.single-template-container{
    border: 1px solid #ddd;
    padding: 10px 15px;
    margin:10px 0px;
    cursor: pointer;
}
.subject-text{
    font-size: 18px;
    font-weight: 700;
    color: #000;
}
.subject-text:hover{
    text-decoration: underline;
    cursor: pointer;
}
.template-separator{
    border-top: 1px solid #000;
}
.language-select{
    width:100%;
    height: 50px;
}
.language-select:focus{
    box-shadow: inset 0 0px 0px #000008, 0 0 6px #66AFE9;
    border: 1px solid #b0a8a8;
    outline: none;   
}
.select-option{
    padding: 10px;
}
.subject-input{
    width: 100%;
    height: 70px;
    border: 1px solid #b0a8a8;
    padding:0px 10px
}
.subject-input:focus{
    box-shadow: inset 0 0px 0px #000008, 0 0 6px #66AFE9;
    border: 1px solid #b0a8a8;
    outline: none;    
}
.delete-template-button{
    width: 200px;
    height: 40px;
    background-color: white;
    color: var(--selected-primary-button-color);
    font-weight:bold ;
}
.save-template-button{
    width: 200px;
    height: 40px;
    background-color:var(--selected-primary-button-color);
    color: white;
    font-weight:bold ;
}
.feedback-input{
width: 100%;
height: 40px;
border: none;
outline: none;
}