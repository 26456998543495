.inactive-tab {
    height: 46px;
    line-height: 46px;
    background-color: var(--selected-primary-button-color);
    color: white;
    font-size: 14px;
    width: 180px;
    text-align: center;
    font-weight: bold;
    margin: 0 2px;
    border: none;
    outline: none;
  }
  .active-tab {
    height: 46px;
    line-height: 46px;
    background-color: var(--secondary-color);
    color: white;
    font-size: 14px;
    width: 180px;
    text-align: center;
    font-weight: bold;
    margin: 0 2px;
    border: none;
    outline: none;
  }
.name_message_header{
    height: 50px;
    width:100%;
    background: var(--selected-primary-button-color);
}
.name_message_header p{
    color: white;
    line-height: 50px;
    font-size: 18px;
    margin-left: 35px;
}
.name_message_header span{
    text-decoration: underline;
}
.query-component-container{
    background: var(--background-color);
    padding:42px 20px 20px 20px;
}
.tab-grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 5px;
}

@media only screen and (max-width: 600px) {
    .tab-grid{
        display: grid;
        grid-template-columns:auto;
        grid-gap: 5px;
    }
  }
  
.active{
    margin-bottom: 0px;
}
.tab-button{
    height: 46px;
}
.top-border{
    border-top: 3px solid black;
    margin-top: 25px;
}
.display-selected-query{
    margin-top: 25px;
}
.clear-button{
    border: 1px solid black;
    padding: 3px;
    border-radius: 50px;
    font-size: 14px;
    margin-left: 5px;
    cursor: pointer;
    margin-right: 15px;
}
.bottom-border{
    margin-top: 20px;
    border-bottom: 1px solid black;
}
.query-container{
    border-radius: 50px;
    margin:0 5px;
    display: flex;
    align-items: center;
    position: relative;
}
.cancel-icon{
    width:30px !important;
    height: 30px !important;
    background-color: var(--selected-primary-button-color);
    border-radius: 50%;
    position: absolute;
    left: -20px;
    color: var(--input-label-color);
    top: -5px;
}
.query-button{
    background-color: var(--selected-primary-button-color);
    color: var(--sign-in-text-color);
    width: 100%;
    height: 40px;
}
.save-query-button{
    background-color:white;
    color: var(--selected-primary-button-color);
    width: 100%;
    height: 40px;
    border:1px solid var(--selected-primary-button-color);
    outline: none;
}
.query{
    background-color: var(--selected-primary-button-color);
    height: 22px;
    line-height: 18px;
    margin-left: 26px;
    padding-right: 5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.gender-value-container{
    padding: 10px 20px;
    border: 1px solid #ece;
    /* height: 90px; */
}
.advance-query-text{
    font-size: 18px;
    color: var(--selected-primary-button-color);
    text-decoration: underline;
}
.advance-cancel-container{
    border: 2px solid var(--selected-primary-button-color);
    padding: 7px;
    margin:10px 0px;
    margin-right: 25px;
}