*{
    --primary-color:#c1c1c1;
}
.accordion-header{
    font-size: 18px;
    font-weight: bold;
    color: #333;
    padding:25px 15px;
    border:1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}
.delete-accordion-card{
    background-color: var(--background-color);
    padding: 25px;
}
.rotate90{
    transform: rotate(180deg);
}
.rotate0{
    transform: rotate(0deg);
}
.delete-button-width-for-mobile{
    width: 100%;
}
@media screen and (max-width:769px) {
    .delete-button-width-for-mobile{
        width: 201%;
    }
}