.query-title-containers{
    background: var(--background-color);
    padding: 12px;
    border-bottom: 1px solid black;
    border: 1px solid #ece8dd;
}
.not-expand{
    height: 75px;
    overflow: hidden;
}
.expand{
    height: max-content;
}