.message-center-container {
  padding: 25px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  gap: 15px;
}
.message-single-grid {
}
.message-center-title {
  color: #009900;
  font-size: 18px;
  cursor: pointer;
}
.message-center-title:hover {
  color: #23527c;
  text-decoration: underline;
}
