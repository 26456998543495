.school_group_message {
  display: grid;
  grid-template-columns: 550px 1fr;
  gap: 15px;
}
.schoolit-group-message-details {
  border: 1px solid #ddd;
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
}
.message-open-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
}
.single-count-container {
  height: 30px;
  border: 1px solid #ddd;
  line-height: 30px;
  position: relative;
}
