.counselorRegWrap {
    .header {
        padding: 14px;
        background-color: var(--white-color);
        margin-bottom: 80px;

        .colorLogo {
            width: 130px;
        }
    }

    .connectBlockInner {
        margin-left: 15px;
        margin-right: 15px;
    }

    .boxShadow {
        background: var(--white-color);
        border: 1px solid rgba(138, 165, 182, 0.2);
        box-shadow: 0px 8px 8px rgba(141, 161, 172, 0.15);
        border-radius: 15px;
    }

    .connectBlock {
        max-width: 455px;
        margin: 0 auto;
        margin-bottom: 30px;
        padding: 55px 80px 30px 80px;

        .connect-title {
            font-weight: var(--font-bold);
            font-size: var(--font32);
            line-height: 1.2;
            color: var(--color7);
            margin-bottom: 10px;
        }

        p {
            margin-top: 45px;
                font-family: var(--secondary-font-family);
                font-weight: var(--font-medium);
                font-size: var(--font16);
                letter-spacing: -0.12px;
                line-height: 1.2;
                color: var(---secondary-color);
                margin-bottom: 10px;            
        }
    }

    .backLink {
        display: block;
        font-weight: var(--font-medium);
        font-size: var(--font16);
        line-height: 1.2;
        color: var(--color7);
        margin-bottom: 15px;

        &:hover {
            text-decoration: none;
        }
    }
}