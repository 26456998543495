.schoolit-student-container {
  padding: 15px;
}
.student-heading-container {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
}
.student-heading-container p {
  font-size: 30px;
  margin: 20px 0 10px 0;
}
.new-students-button {
  border: none;
  outline: none;
  background-color: rgb(239, 239, 239);
  padding: 10px;
  border-radius: 5px;
  height: fit-content;
}
.school-name-container {
  padding: 15px;
}
.school-name-container-heading {
  font-size: 18px;
}
.school-name {
  cursor: pointer;
  color: white;
  background-color: var(--success-color);
  padding: 10px;
  border-radius: 5px;
  width: max-content;
}
.schoolit-student-list-container {
  padding: 15px;
}
.search-student-input {
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid black;
  outline: none;
}
.search-student-input:focus {
  border: 1px solid #66afe9;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.search-student-input2 {
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid #cdcdcd;
  outline: none;
}
.search-student-input2:focus {
  border: 1px solid #66afe9;
  border-color: #66afe9;
  /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%); */
}
.student-id-button {
  border: 1px solid white;
  outline: none;
  color: white;
  background-color: #057ebb;
  padding: 3px 7px;
}
.create-student-container {
  position: fixed;
  right: 0;
  top: 59px;
  width: 550px;
  height: 95vh;
  background: #efefef;
  box-shadow: 2px 0 20px 10px rgb(0 0 0 / 20%);
  padding: 15px;
  overflow-y: scroll;
}
.help-block {
  font-size: 14px;
  color: #737373;
  margin: "5px 0 10px 0";
}

.schoolit-student-container .student-list {
  border: 0.5px solid #cccccc;
  padding: 10px;
  font-size: 14px;
  width: 350px;
  cursor: pointer;
  margin-left: 20px;
}
.schoolit-student-container .student-list-active {
  background-color: #e4e4e4;
}
.schoolit-student-container .student-list:hover {
  background: #cccccc;
}