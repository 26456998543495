.commonBtn {
    font-family: var(--base-font-family);
    font-weight: var(--font-bold);
    font-size: var(--font14);
    line-height: 1.2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 3px;
    cursor: pointer;
    max-width: 100%;
    border-radius: 7px;
    box-shadow: 0px 4px 28px rgba(44, 18, 27, 0.1);
  }