.forgotPswdWrap {
    height: 100vh;


    .forgotPswdWrapInner {
        position: relative;
        height: 100%;
        overflow-y: scroll;

        .forgotPswdBg {
            width: 100%;
            // height: 395px;
            height: 51vh;
            object-fit: cover;
        }

        .forgotPswdArea {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 775px;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .logoImg {
                width: 185px;
                margin-top: 50px;
                margin-bottom: 45px;
            }

            .forgotFormWrap {
                margin: 15px;
                max-width: 775px;
                width: 100%;

                .boxWrap {
                    background: var(--white-color);
                    border: 1px solid rgba(138, 165, 182, 0.2);
                    box-shadow: 0px 8px 8px rgba(141, 161, 172, 0.15);
                    border-radius: 15px;

                    padding: 30px 15px 30px 15px;
                    max-width: 745px;
                    width: 100%;
                    margin: 0 auto;

                    .boxwrapInner {
                        max-width: 595px;
                        width: 100%;
                        margin: 0 auto;
                    }
                }

                .forgotTitle {
                    font-weight: var(--font-bold);
                    font-size: var(--font24);
                    line-height: 1.2;
                    letter-spacing: -0.3px;
                    color: var(--primary-font-color);
                    margin-bottom: 10px;
                }

                p {
                    font-weight: var(--font-medium);
                    font-size: var(--font20);
                    line-height: 1.2;
                    letter-spacing: -0.2px;
                    color: var(--secondary-font-color);
                    margin-bottom: 50px;
                }

                .resendBtn {
                    font-size: var(--font16);
                    margin-top: 10px;
                    margin-bottom: 40px;
                }

                .rememberText {
                    padding-bottom: 10px;
                    font-weight: var(--font-medium);
                    font-size: var(--font14);
                    line-height: 1.2;
                    letter-spacing: -0.2px;
                    color: var(--color7);
                }
            }
        }
    }
}

@media screen and (orientation:landscape) and (min-device-width: 319px) and (max-device-width: 991px) {
    .forgotPswdWrap {
        .forgotPswdWrapInner {
            .forgotPswdArea {

                .logoImg {
                    margin-top: 30%;
                    margin-bottom: 10px;
                }
            }
        }
    }
}