.goal-dropdown-menu:hover {
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border-radius: 3px;
  color: white;
  background-color: #337ab7;
  list-style: none;
  cursor: pointer;
}
.goal-dropdown-menu {
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  border-radius: 3px;
  color: black;
  background-color: white;
  list-style: none;
  cursor: pointer;
}
