.image-drop-wrapper{
    margin-top: 25px;
    border: 3px solid black;
    height:665px;
    width:100%;
    background-color: white;
}
.image-drop-container{
    height: 460px;
    margin:25px;
    border: 2px dashed #969696;
    background-color: white;
    text-align: center;
    padding: 30px 0px;
}
.banner-placeholder-image{
    height: 130px;
    width:130px;
    margin: auto;  
}
.or_text{
    width: 90%; 
   text-align: center; 
   border-bottom: 1px solid #000; 
   line-height: 0.1em;
   margin: 10px 15px; 
}
.or_text span{
    background:#fff; 
    padding:0 10px; 
}
.image-drop{
    height: 50px;
}
.upload-button{
    margin-top: 15px;
    background-color: transparent;
    width: 90%;
    background: var(--selected-primary-button-color);
    color: white;
    font-weight: 700;
    height: 40px;
    line-height: 35px;
}