.create-school-button{
        color: #fff;
        background-color: #057ebb;
        border: none;
        border-radius: 3px;
        padding: 10px 12px;
        outline: none;
        font-size: 14px;
        margin: 0 1px;
}
.cancel-admin-button{
    background-color: #d9534f;
    border: none;
    outline: none;
    color: white;
    padding: 5px 15px;
    border-radius: 3px;
    margin-right: 25%;
    margin-top: 5%;
}