.student-import-modal {
  padding: 15px;
  background-color: #efefef;
}
.value-badge {
  background-color: #777;
  color: white;
  padding: 4px 8px;
  border-radius: 2px;
}
.student-import-update-button {
  border-color: green;
  color: white;
  outline: none;
  background-color: #090;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 12px;
}
.student-import-update-button:hover {
  border-color: #204d74;
  color: white;
  outline: none;
  background-color: #286090;
}
.student-import-cancel-button {
  border-color: #d43f3a;
  color: white;
  outline: none;
  background-color: #d9534f;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 12px;
}
.student-import-cancel-button:hover {
  border-color: #ac2925;
  color: white;
  outline: none;
  background-color: #c9302c;
}
.error-container-modal {
  background-color: #ffffd7;
}
