.textFieldfeedback .MuiInput-root:after{
 border-bottom:1px solid black
}
.textFieldfeedback .Mui-focused{
    color: #6f6a6a !important;
    font-weight: bold;
    font-size: 16px;  
}
.textFieldfeedback .MuiInputLabel{
    padding: 0 15px;
}
.student-list-modal-container{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
}