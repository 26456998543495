.add-new-course {
  background: #f0ad4e;
  border-color: #eea236;
  color: white;
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 4px;
}
.add-new-course:hover {
  background-color: #ec971f;
  border-color: #d58512;
  color: white;
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 4px;
}
.remove-class-button {
  padding: 5px 15px;
  outline: none;
  border: none;
  background-color: #d9534f;
  color: white;
  border-radius: 3px;
  margin-right: 15px;
}

.remove-class-button:hover {
  padding: 5px 15px;
  outline: none;
  border: none;
  background-color: #c9302c;
  color: white;
  border-radius: 3px;
  margin-right: 15px;
}
