.admin-canned-container {
  padding: 15px;
}

.school-name-container {
  padding: 15px;
  position: relative;
}

.canned-message-heading {
  justify-content: space-between;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 10px;
  padding: 0 20px;
}

.admin-school-name-list {
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  width: 480px;
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 5px;
  padding: 10px 0;
}

.admin-school-list {
  padding: 5px 15px;
}
.admin-school-list:hover {
  background-color: #057ebb;
  color: white;
}

.search-admin-school-input {
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid rgb(56, 56, 56);
  outline: none;
  width: 480px;
  height: 35px;
  padding: 5px 10px;
  margin-bottom: 10px
}
.search-admin-school-input:focus {
  border: 1px solid #66afe9;
  border-color: #66afe9;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.admin-canned-container .message-list {
  border: 0.5px solid #cccccc;
  background: white;
  padding: 10px 10px;
  font-size: 14px;
  width: 350px;
  min-height: 40px;
  cursor: pointer;
  margin-left: 20px;
}
.admin-canned-container .message-list:hover {
  background: #cccccc;
}

.require-input-name:focus {
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  border-color: #66afe9;
  border: 1px solid #66afe9;
  outline: none;
}

.create-message-button {
  color: #fff;
  background-color: #057ebb;
  border: none;
  border-radius: 3px;
  padding: 5px 15px;
  outline: none;
  margin: 5px;
}
.create-message-button:hover {
  background-color: #0e6b7e;
}

.Create-Canned {
  padding: 15px;
}

.Create-Canned .disabled-language-button {
  border: none;
  outline: none;
  padding: 5px 15px;
  border-radius: 3px;
  background-color: #ddd;
}

.save-bottom-row {
  display: flex;
  margin-top: 40px;
  justify-content: center;
  width: 80%;
  gap: 12%;
}

.input-error {
  /* box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgba(233, 102, 102, 0.6); */
  border-color: #e96666;
  border: 1px solid #e96666;
  outline: none;
}

.college-input-container {
  display: grid;
  grid-template-columns: 25% 70%;
  align-items: center;
  gap: 5%;
  margin: 20px 5px;
}

.college-input-container input {
  padding: 4px 10px;
  border: 1px solid #8e8e8e;
  border-radius: 4px;
}
