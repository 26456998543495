.modal-dialog{
 max-width: 598px!important;   
}
.modal-header > .modal-title{
    width: 100%;
}
.save-modal-input{
    width: 100%;
    height: 40px;
    margin-top: 17px;
}
.save-modal-input::placeholder{
    color: #555;
    font-size:14px;
    opacity: 0.8;
    padding:0 15px
}
.save-modal-input:focus{   
    border-color: #66afe9;
    outline: 0;
    border: 1px solid #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
