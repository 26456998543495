.create-maintain-group-button {
  color: #fff;
  background-color: #057ebb;
  border: none;
  border-radius: 3px;
  padding: 5px 7px;
  outline: none;
  font-size: 14px;
  margin: 0 1px;
}
.create-maintain-group-button:hover {
  background-color: #00bff3;
  border-color: #008fb6;
}
.maintain-group-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 400px 550px;
  gap: 25px;
}
.maintain-group-name {
  border: 0.5px solid #ccc;
  padding: 10px;
  cursor: pointer;
  color: #555555;
}
.maintain-group-name:hover {
  background-color: #f5f5f5;
}
.active-group-class {
  background-color: var(--success-color);
  color: white;
  padding: 10px;
}
.maintain-create-group-name {
  padding: 2px;
  width: 60%;
}
.dropdown-grade-background {
  margin-right: 15px;
  padding: 2px 5px;
  color: white;
  background: #777;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 600;
}
.maintain-group-student-list {
  padding: 5px 50px 5px 25px;
}
.maintain-group-student-list:hover {
  background-color: #057ebb;
  color: white;
}
.maintain-group-student-dropdown-menu {
  padding-left: 0px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
