.create-new-group-header{
    background-color: var(--background-color);
    border-top: 2px solid #333;
    border-bottom:2px solid #333;
    line-height: 20px;
}
.add-create-group-button{
    background-color: var(--selected-primary-button-color);
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 17px 20px;

}
.new-group-message-input::placeholder{
    font-weight: bold;
    color: #6f6a6a;
}
.add-create-group-button span{
    font-size: 16px;
} 
.student-name-input{
    height: 46px;
    width:100%
}
.group-student-list{
    width: 100%;
}
.group-student-list :hover{
    background-color: #337AB7;
    color: white;
}
.grade-design{
    background-color: #777777;
    padding: 0px 7px;
    color: white;
    font-weight: bold;
    margin-right: 5px;
    border-radius: 15px;
}
.group-student-list-modal-container{
    display: grid;
    grid-template-columns: 80px 140px 240px 175px 120px 50px;
    place-items: center;
}
.add-student-input{
    width:100%;
    padding: 5px;
    height:55px;
}