.student-message-container {
  padding: 15px;
  position: relative;
}
.school_it-message-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}
.schoolIT-student-message-container {
  border: 1px solid #ddd;
  padding: 10px;
  cursor: pointer;
}
.new-student-message-container {
  width: 495px;
  min-height: calc(100vh + 110px);
  overflow-y: scroll;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #efefef;
  box-shadow: 2px 0 20px 10px rgb(0 0 0 / 20%);
  padding: 15px;
  margin-top: 60px;
}
.select-schedule-button {
  font-size: 12px;
  color: #333;
  border: none;
  outline: none;
  background-color: white;
  padding: 5px 30px;
}
.canned-message-container {
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
  color: #555;
}
.canned-message-container:hover {
  background: #f5f5f5;
  color: #555;
}
.clarifiction-text {
  color: #777;
}
.clarifiction-text:hover {
  color: #337ab7;
  text-decoration: underline;
}
.message-active-class {
  border-color: #c1e2b3;
  background-color: #dff0d8;
}
