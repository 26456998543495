.modalClassName{
    width:100%;
    border: 3px solid black;
}
.modalClassName .modal-dialog .modal-content{
    border: 3px solid black; 
}
.modalContentClassName .modal-header{
  background-color: #EDE7DF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid black;
}
.modalContentClassName .modal-body{
    padding: 0px;
}
.modalAccordion{
    background: #2C121B!important;
    border-radius: 0px!important;
    height: 70px!important;
    color: white;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.cannedMsgContainer{
    margin: 10px;
    background: white; 
    padding: 10px; 
    font-size: 14px;
    cursor: pointer; 
}
.cannedWrapper{
    height: 300px;
    overflow-y: scroll;
    background-color: #EDE7DF;
}
.nameorgroupname{
    width: 100%;
    height: 65px;
    padding: 6px 10px;
}
.modalContentClassName .modal-header .modal-title{
    font-size: 20px;
    font-weight: bold;
    width: 100%;
}
.header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.textAreaInput{
    width: 100%;
    height: 100%;
    padding: 10px;
}
.reply_container{
    background-color: #2C121B;
    margin-top: -8px;
    height: 76px;
    color: #6f6a6a;
}
.student-container{
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 310px;
    overflow-y: scroll;
    background: white;
    width: 100%;
}
.single-student{
    padding: 5px 5px;
    cursor:pointer
}
.single-student:hover{
    background: #EDE7DF;
}
.check{
    color: white;
}
.cancelAttachment{
    color: #008000;
}
.sendmessage{
    background-color: transparent;
    border: none;
    color: #6f6a6a;
    outline: none;
}
@media screen and (min-width:576px) {
    .modalClassName .modal-dialog{
        max-width: 826px!important;
        margin: auto;
    }
    .reply_container{
        height: auto;
    }
}