.interest-main {
    border: 0.76621px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0px 3.06484px 6.12968px rgba(0, 0, 0, 0.04), 0px 0px 1.53242px rgba(0, 0, 0, 0.06), 0px 0px 0.76621px rgba(0, 0, 0, 0.04);
    border-radius: 6.12968px;
  
    .card-body {
      padding-top: 35px;
      padding-bottom: 24px;
    }
  
    .labelBold {
      font-weight: var(--font-medium);
      font-size: var(--font14);
      line-height: 1.1;
      color: #2C121B;
      font-weight: bold;
    }

    label {
        font-weight: var(--font-medium);
        font-size: var(--font14);
        line-height: 1.1;
        color: #2C121B;
    }
  
    .shadow-box {
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      margin-bottom: 12px;
    }
  
    .row {
      margin: 0 -35px;
    }
  
    .row [class*='col-'] {
      padding: 0 35px;
    }
  
    .btnWrapper {
      margin-left: -5px;
      margin-right: -5px;
  
      .btnInner {
        margin-top: 80px;
        padding-left: 5px;
        padding-right: 5px;
        display: flex;
        flex: 1;
  
        .passionBtn{
          margin-bottom: 12px;
        }
      }
    }
  }

  .custom-datepicker {
    width: 300px;
    padding-right: 35px; 
  }
  
  
  .title-main {
    margin-bottom: 8px;
  }
  
  .langSwitch {
    margin-bottom: 8px;
  
    .switchIncon {
      color: var(--white-color);
      font-weight: 700;
      font-size: 17px;
      line-height: 1.2;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
  
      &.uncheckIcon {
        margin-right: 2px;
      }
  
      &.checkIcon {
        margin-left: 2px;
      }
    }

    .search-student-school-input {
      border-radius: 5px;
      padding: 5px 15px;
      border: 1px solid rgb(56, 56, 56);
      outline: none;
      width: 100%; /* Set width to 100% for responsiveness */
      max-width: 480px; /* Set a max-width for larger screens */
      height: 35px;
      margin-bottom: 10px;
      box-sizing: border-box; /* Ensures padding doesn't affect the width */
    }
    
    .search-student-school-input :focus {
      border: 1px solid #66afe9;
      border-color: #66afe9;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
    }
    
    @media (max-width: 768px) {
      .search-student-school-input {
        padding: 5px 10px; /* Adjust padding for smaller screens */
        max-width: 100%; /* Allow full width on smaller screens */
      }
    }
    
    @media (max-width: 576px) {
      .search-student-school-input  {
        padding: 5px 8px; /* Further reduce padding for very small screens */
        height: 30px; /* Adjust height for very small screens */
      }
    }

    .student-school-name-container {
      position: relative; /* Position container relative to position pseudo-element */
    }

    @media (max-width: 768px) {
      .maintain-group-student-dropdown-menu {
        max-width: 100%; /* Full width on smaller screens */
        max-height: 300px; /* Adjust height for smaller screens */
      }
    }
    
    @media (max-width: 576px) {
      .maintain-group-student-dropdown-menu {
        max-height: 200px; /* Further adjust height for very small screens */
        padding: 5px; /* Reduce padding for very small screens */
      }
    }
  }    
    