.verifyEmailWrap {
    height: 100vh;
    position: relative;

    .verifyEmailBoxWrap {
        background: url('https://siembra-frontend.s3.us-west-1.amazonaws.com/images/forgot-pswd-bg.png') no-repeat center;
        background-size: cover;
        // height: 395px;
        height: 48vh;
    }

    .verifyEmailBoxArea {
        // margin-top: 10px;
        padding-bottom: 15px;

        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);

        .logoImg {
            margin-top: 45px;
            margin-bottom: 40px;
            width: 180px;
        }
    }

    .verifyEmailBox {   
        
        padding: 50px 70px 30px 70px;
        max-width: 610px;
        margin-left: auto;
        margin-right: auto;
        background: var(--white-color);
        border: 1px solid rgba(138, 165, 182, 0.2);
        box-shadow: 0px 8px 8px rgba(141, 161, 172, 0.15);
        border-radius: 15px;
        @media(max-width: 600px){
            padding: 50px 20px 30px 20px;
        }
        .verifyMessageIcon {
            width: 160px;
            margin-bottom: 45px;
        }

        .verifyTitle {
            font-weight: var(--font-medium);
            font-size: var(--font24);
            line-height: 1.2;
            letter-spacing: -0.3px;
            color: var(--primary-font-color);
            margin-bottom: 10px;
        }

        p {
            font-weight: var(--font-medium);
            font-size: var(--font20);
            line-height: 1.6;
            letter-spacing: -0.2px;
            color: var(--secondary-font-color);
            margin-bottom: 10px;
        }
    }

    .verifyBtn {
        margin-top: 50px;
        font-size: var(--font16);
    }
}

@media screen and (orientation:landscape) and (min-device-width: 319px) and (max-device-width: 991px) {
    .verifyEmailWrap {
        .verifyEmailBoxArea {
            .logoImg {
                margin-top: 30%;
                margin-bottom: 10px;
            }
        }
    }
}