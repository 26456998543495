@import url("./components/query/Query.css");
@import url("./components/query/singlequery/SingleQuery.css");
@import url("./components/query/gpa_slider/GPA_SLIDER.css");
@import url("./components/newMessageModal/NewMessageModal.css");
@import url("./components/message/Message.css");
@import url("./components/singlemessage/messageList.css");
@import url("./components/singlemessage/singleMessage.css");
@import url('./components/saveModal/SaveModal.css');
@import url("./components/schoolquery/SchoolQuery.css");
@import url("./components/queryresult/QueryResult.css");
@import url("./components/deleteModal/DeleteModal.css");
@import url("./components/messageTemplate/MessageTemplate.css");
@import url("./components/digitalCampaign/DigitalCampaign.css");
@import url('./components/digitalCampaign/bannerComponent/BannerComponent.css');
@import url("./components/datepicker/Datepicker.css");
@import url('./components/recruiterprofile/Profile.css');

@import url("./components/counselor/counelorCollegeConnectStudent/CounselorCollegeConnectStudent.css");
@import url("./components/counselor/manageStudent/ManageStudent.css");
@import url("./components/counselor/counselorMessageModal/CounselorMessageModal.css");
@import url("./components/counselor/detailStudentStatus/DetailStudentStatus.css");
@import url("./components/counselor/counselorMessageModal/counselorGroupMessage/CounselorGroupMessage.css");
@import url("./components/counselor/counselorMessageModal/manageGroupList/ManageGroupList.css");
@import url("./components/counselor/currentStudent/CurrentStudent.css");
@import url("./components/counselor/inviteStudent/InviteStudent.css");
@import url("./components/counselor/addExistingStudent/AddExistingStudent.css");
@import url("./components/counselor/studentImportConfirmationModal/StudentImportConfirmationModal.css");
@import url("./components/counselor/counselorCalendar/CounselorCalendar.css");
@import url("./components/counselor/deleteMessage/DeleteCounselorMessage.css");
@import url("./components/counselor/createCalendarEvent/CreateCalendar.css");
@import url("./components/counselor/counselorMessageTemplate/CounselorMessageTemplate.css");
@import url("./components/superCounselor/DownloadSummary.css");

@import url("./components/school_it/Profile/SchoolitProfile.css");
@import url("./components/school_it/Student/Student.css");
@import url("./components/school_it/CsvImport/CsvImport.css");
@import url("./components/school_it/DownloadStudentSummary/DownloadStudentSummary.css");
@import url("./components/school_it/MaintainGroup/MaintainGroup.css");
@import url("./components/school_it/StudentMessage/StudentMessage.css");
@import url("./components/school_it/MessageCenter/MessageCenter.css");
@import url("./components/school_it/GroupMessage/GroupMessage.css");
@import url("./components/school_it/GoalCriteriaOrSetup/GoalCriteriaOrSetup.css");
@import url("./components/school_it/GoalCriteriaOrSetup/ViewRequiredCourseSet/ViewRequiredCourseSet.css");
@import url("./components/school_it/GoalCriteriaOrSetup/MapCourseData/MapCourseData.css");
@import url("./components/school_it/GoalCriteriaOrSetup/ViewGoalMap/ViewGoalMap.css");
@import url("./components/school_it/GoalCriteriaOrSetup/CreateGoalMap/CreateGoalMap.css");
@import url("./components/school_it/GoalCriteriaOrSetup/ManageDataSource/ManageDataSource.css");
@import url("./components/school_it/GoalCriteriaOrSetup/CreateGoalMap/CreateGoalMap.css");
@import url("./components/school_it/GoalCriteriaOrSetup/CreateDataSource/CreateDataSource.css");
@import url("./components/school_it/GoalCriteriaOrSetup/UploadDataSource/UploadDataSource.css");
@import url("./components/school_it/GoalCriteriaOrSetup/MapCollege/MapCollege.css");
@import url("./components/school_it/GoalCriteriaOrSetup/MapGoal/MapGoal.css");

/*admin */
@import url("./components/admin/CannedMessages/CannedMessages.css");
@import url("./components/admin/Invites/Invite.css");
@import url("./components/admin/Student/Student.css");
.reply_container {
  background-color: #2c121b;
  color: #6f6a6a;
  height: 76px;
  margin-top: -8px;
}
.no-margin-bottom {
  margin-bottom: 0px;
}
.bold {
  font-weight: bold;
}
.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.text-color{
  color:var(--input-label-color);
}
.font{
  font-size: 14px;
}
.text-color {
  color: var(--input-label-color);
}
.font {
  font-size: 14px;
}
.cursor {
  cursor: pointer;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.student-summary-container {
  margin: auto;
}
.light-theme {
  --selected-primary-button-color: #2c121b;
  --background-color: #ede7df;
  --input-label-color: #6f6a6a;
  --sign-in-text-color: #ffffff;
  --title-text-color: #000000;
  --border-color: #b7b6b6;
  --success-color: #008000;
  --at-risk-color: #aa1513;
  --at-red-color: red;
  --yellow-color: #fcd24d;
  --primary-color: #C1C1C1;
  --brown-color: #800000;
  --pink-color: #ffc0cb;
  /* --secondary-color:#008000; */
  --secondary-color: #049804;
  --message-time-color: #049804;
  /* --message-time-color:#008000; */
  --text-color: #333333;
  --tab-button-color: #a68792;
}
.dark-theme {
  --background-color: black;
  --selected-primary-button-color: white;
  --input-label-color: black;
  --title-text-color: white;
  --border-color: white;
  --sign-in-text-color: red;
}

/* New */
:root {
  --base-font-family: "DM Sans", sans-serif;
  --secondary-font-family: "Inter", sans-serif;
  --ternary-font-family: "Roboto", sans-serif;
  --primary-color: #049804;
  --secondary-color: #2c121b;
  --white-color: #ffffff;
  --primary-font-color: #040c22;
  --secondary-font-color: #6a6e83;
  --color3: #1a202c;
  --color4: #9294a3;
  --color5: #f5f6f7;
  --color6: #232222;
  --color7: #1c1c1c;
  --color8: #f4f7f9;
  --color9: #1e1e1e;
  --colo10: #f5f5f5;
  --color11: #fc574c;
  --color12: #303030;
  --color13: #9e9e9e;

  /* font weight */
  --font-normal: 400;
  --font-medium: 500;
  --font-600: 600;
  --font-bold: 700;

  /* Font size */
  --font10: 10px;
  --font12: 12px;
  --font13: 13px;
  --font14: 14px;
  --font16: 16px;
  --font20: 20px;
  --font22: 22px;
  --font23: 23px;
  --font24: 24px;
  --font32: 32px;
  --font34: 34px;

  /* Primary Button - fill */
  --primary-btn-text-color: var(--white-color);
  --primary-btn-bg-color: var(--secondary-color);

  /* Secondary Button - white */
  --secondary-btn-text-color: var(--secondary-color);
  --secondary-btn-bg-color: var(--white-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app-main .app-main__inner {
  position: relative;
}

body {
  font-family: var(--base-font-family);
  color: var(--primary-font-color);
  font-weight: var(--font-normal);
  font-size: var(--font16);
  line-height: 1.2;
  background-color: var(--colo10);
}

.header-dots .icon-wrapper-alt.notifyIconBlock {
  background-color: #f4f5f9;
  border: 0.5px solid rgba(106, 110, 131, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-dots .icon-wrapper-alt.notifyIconBlock .badge-dot.notifyBadge {
  padding: 2px;
  text-indent: 0;
  right: -3px;
  width: 15px;
  height: 15px;
  background-color: #049804;
  color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Scrollbar */
/* Change scrollbar width */
::-webkit-scrollbar {
  width: 4px;
}

/* Change scrollbar color */
::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.primaryText {
  color: #049804;
}

img {
  max-width: 100%;
}

.mainTitle {
  font-weight: var(--font-medium);
  font-size: var(--font22);
  line-height: 1.2;
  letter-spacing: -0.2px;
  text-transform: capitalize;
  color: var(--primary-font-color);
  margin-bottom: 10px;
}

.App-link {
  color: #545cd8;
}

/* Required field */
.requiredField {
  color: var(--primary-color);
}

/* custom checkbox */
.customCheckbox {
  padding-left: 30px;
}

.customCheckbox label {
  font-weight: var(--font-normal);
  font-size: var(--font12);
  letter-spacing: 0.02em;
  color: rgba(35, 34, 34, 0.7);
}

.customCheckbox input[type="checkbox"] {
  accent-color: var(--primary-color);
  position: absolute;
  left: 0;
  margin: 0;
  width: 18px;
  height: 18px;
  border: 1px solid rgba(28, 28, 28, 0.1);
  border-radius: 3px;
}

select {
  color: #2c121b !important;
}

.form-control {
  border-radius: 10px;
  height: calc(1.5em + 0.75rem + 10px);
  background: var(--color5);
  border: 1px solid rgba(28, 28, 28, 0.1);
}

.modal-dialog-centered {
  box-shadow: none !important;
}

.modal-box .modal-content {
  background: #ffffff;
  border-radius: 20px !important;
  border: 0;
}

.modal-box .modal-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #fff;
  justify-content: center;
}

.modal-box .modal-header .modal-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #2c121b;
}

.modal-box .modal-footer {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.form-group-select {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 1px rgb(0 0 0 / 4%);
  border-radius: 4px;
  font-size: var(--font14);
  font-weight: var(--font-medium);
}

.required-field {
  color: #545cd8;
  font-size: 20px;
  vertical-align: middle;
}

.error {
  color: red;
  font-size: 12px;
}

::-webkit-input-placeholder {
  color: rgba(106, 110, 131, 0.8) !important;
  font-size: var(--font14);
}

.custom-lable {
  font-size: 16px;
  color: #232222;
}

.cursor-pointer {
  cursor: pointer;
}

.search-input {
  font-size: 14px;
  margin-right: 15px;
}

.header-title p {
  color: rgb(8, 58, 191);
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-transform: capitalize;
}

.form-control:focus {
  /* color: #495057 !important;
  border-color: #ced4da !important; */
  /* color: var(--secondary-color); */
  outline: 0 !important;
  box-shadow: none !important;
}

/* =============== Buttons ================= */
/* Fill btn Primary */
.primaryBtn {
  color: var(--primary-btn-text-color);
  background: var(--primary-btn-bg-color);
  border: 1px solid var(--primary-btn-bg-color);
}

/* White Btn */
.secondaryBtn {
  background: var(--secondary-btn-bg-color);
  color: var(--secondary-btn-text-color);
  border: 1px solid var(--secondary-btn-text-color);
}

/* page main title */
.title-main {
  font-weight: var(--font-medium);
  font-size: var(--font20);
  line-height: 1.3;
  color: var(--color3);
}

.shadow-box {
  background: #ffffff;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  padding: 15px;
}

label {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #2c121b;
}

.success_alert {
  color: #3c763d;
  background-color: rgb(60, 118, 61, 0.5);
  padding: 16px;
}

.error_alert {
  color: #ff3333;
  background-color: rgb(255, 51, 51, 0.5);
  padding: 16px;
}

/* .vertical-nav-menu .metismenu-container .metismenu-container.visible>.metismenu-item::before {
  content: "";
  height: 5px;
  width: 5px;
  background: #fff;
  border-radius: 50%;
  display: block;
  top: 14px;
  position: absolute;
  left: 0px;
} */

.vertical-nav-menu .metismenu-container .metismenu-link {
  font-size: var(--font14);
  font-weight: var(--font-medium);
  /* width: max-content; */
  /* min-width: fit-content; */
}
.vertical-nav-menu > ul {
  width: fit-content;
}
.vertical-nav-menu
  .metismenu-container
  .metismenu-container.visible
  > .metismenu-item
  > .metismenu-link {
  font-weight: var(--font-normal);
}

.vertical-nav-menu
  .metismenu-container
  .metismenu-container.visible
  > .metismenu-item
  > .metismenu-link.active::before {
  content: "";
  height: 5px;
  width: 5px;
  background: #fff;
  border-radius: 50%;
  display: block;
  top: 14px;
  position: absolute;
  left: 0px;
}

.vertical-nav-menu .metismenu-container i.metismenu-icon {
  margin-top: -15px;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon,
.vertical-nav-menu .metismenu-container i.metismenu-icon {
  opacity: 1;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon {
  font-size: 30px;
}

.vertical-nav-menu .metismenu-container .metismenu-item {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.vertical-nav-menu .metismenu-container .metismenu-item:last-child {
  border-bottom: none;
}

.vertical-nav-menu .metismenu-container .metismenu-container .metismenu-item {
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.vertical-nav-menu .metismenu-container .metismenu-container.visible {
  margin-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* sidebar menu icons */
.vertical-nav-menu .customIcon {
  /* width: 27px!important;
  height: 27px!important; */
  /* line-height: 0!important;
  font-size: 0!important; */
}

.vertical-nav-menu .goalIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/goalIcon.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .bragBookIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/mybragbook+icon.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .userBookIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/bragBookIcon.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .myReflectionListIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/collegeCheckListIcon.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .signOutIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/signOutIcon.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .messageIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/messageIcon.png")
    no-repeat center;
  background-size: cover;
}

/* .vertical-nav-menu .myReflectionListIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/collegeCheckListIcon.png")
    no-repeat center;
  background-size: cover;
} */

.vertical-nav-menu .collegeCheckListIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/ladders.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .myCalendarIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/myCalendarIcon.png")
    no-repeat center;
  background-size: cover;
}

.vertical-nav-menu .netCalculatorIcon {
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/sidebar/sidebarIcon/netCalculatorIcon.png")
    no-repeat center;
  background-size: cover;
}

.widget-content .widget-content-left .widget-heading {
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #1a202c;
  font-weight: 500;
  outline: none;
}

.user-detail-box {
  background: #f4f5f9;
  border: 0.5px solid rgba(106, 110, 131, 0.2);
  border-radius: 45px !important;
}

.user-detail-box.btn.btn-link:focus,
.user-detail-box.btn.btn-link.focus,
.user-detail-box.btn.btn-link:hover {
  text-decoration: none;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ced4da;
}

.table thead th {
  border-bottom: 2px solid #ced4da;
}

input:focus,
input:focus-visible {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("https://siembra-frontend.s3.us-west-1.amazonaws.com/images/down-arrow.png") no-repeat 100% #fff;
  background-position-x: 96%;
  font-family: Helvetica;
  background-size: 15px;
}

.chekbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
}

/* Hide the browser's default checkbox */
.chekbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  /* top: 0; */
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--color5);
  border-radius: 3px;
  border: 1px solid rgba(28, 28, 28, 0.1);
}

/* On mouse-over, add a grey background color */
.chekbox-container:hover input ~ .checkmark {
  background-color: #f5f6f7;
  border: 1px solid rgba(28, 28, 28, 0.1);
}

/* When the checkbox is checked, add a blue background */
.chekbox-container input:checked ~ .checkmark {
  background-color: #049804;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chekbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chekbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* @media (max-width: 420px) {
  .app-header__logo .logo-src {
    background-position: center !important;
    width: 170px;
    background-size: 100% !important;
  }
} */

.sign-out{
  cursor: pointer;
}
.cursor{
  cursor: pointer;
}
.school-option{
  padding: 5px 12px;
  cursor: pointer;
}
.school-option:hover{
  background:#318CE7;
  color: white;
}

.app-header__logo {
  display: flex;
   /* justify-content: right; */
  background: url('https://siembra-frontend.s3.us-west-1.amazonaws.com/images/side-bg.png') !important;
  background-repeat: no-repeat;
}

.app-header {
  border-bottom: 1px solid rgba(26, 32, 44, 0.1);
}

@media (max-width: 991px) {
  .app-header__logo {
    justify-content: center;
    background-color: transparent;
    border-bottom: none;
  }
}
