/* .active {
    background-color: var(--secondary-color)!important;
    color: white!important;
} */

.save-portfolio-button {
    background-color: var(--selected-primary-button-color);
    color: #fff;
    font-weight: 700;
    height: 46px;
    width: 260px;
}
.download-summary-container{
    background-color: var(--background-color);
    height: 100%; 
    width: fit-content; 
}
.student-statistics-wrapper{
    background-color: var(--background-color);
}
.student-statistics-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
}
.chart-heading{
    font-size: 24px;
    color: #3c9db0;
    margin: 15px 0px;
}
.select-goal{
    height:56px;  
}
.pie-chart-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:15px;
    margin-top: 15px;
}
.student-statistics-wrapper{
    margin: 20px 35px;
    background: white;
    border-right: 2px solid var(--secondary-color); 
    border-left: 2px solid var(--secondary-color)
}
.bar-chart{
    height: 546px;
}
.totalstudent-vs-greenstudentchart-container{
    border: 1px solid black;
    padding: 25px 25px 60px 25px; 
    margin-top: 15px; 
    height:250px
}
@media screen and (max-width:769px){
    .pie-chart-container{
        grid-template-columns: repeat(1, 1fr);
    }
    .student-statistics-wrapper{
        margin: 20px 10px;
        background: white;
        border-right: 2px solid var(--secondary-color); 
        border-left: 2px solid var(--secondary-color)
    }
    .student-statistics-container{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .download-summary-container{
        height: auto;
    }
    .bar-chart{
        height: 220px;
    }
    .totalstudent-vs-greenstudentchart-container canvas{
        height: 110px!important;
    }
}