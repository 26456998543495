.csv-import-container{
    padding: 15px;
}
.import-csv-button{
    background-color: var(--success-color);
    color: white;
    border-radius: 5px;
    outline: none;
    border: none;
    padding:5px 15px
}