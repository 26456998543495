.manage-student-container{
    height:153px;
    background-color:var(--selected-primary-button-color);
    position:relative;
    padding:0 20px 
}
.manage-student-name{
    color: white;
    font-size: 30px;
}
.student-grade{
    color: white;
    font-size: 18px;
}
.manage-student-icon-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap:25px
}
.manage-student-icon{
    width:60px;
    height:67px
}
.manage-active-tab{
    border-bottom: 2px solid var(--secondary-color);
}
.manage-hover-on-tabs :hover{
    border-bottom: 2px solid var(--secondary-color);
}
.manage-goal-image{
    width:50%
}
.name-value-container{
    margin:30px 48px
}
.name-value-container p{
    font-size: 14px;
    color: #333;
}
.name-value-margin{
    margin-left: 80px;
}
.name-value-separator{
    border-bottom: 1px solid #ccc;
}
.separator-gap{
    margin-bottom: 30px;
}