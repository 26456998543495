.rc-slider-track{
    background-color: var(--secondary-color);
}
.rc-slider-handle{
border-color: var(--secondary-color);
width: 26px;
height:26px;
margin-top: -12px;
}
.rc-slider-handle:active {
    border-color: var(--secondary-color);
    box-shadow: 0 0 5px var(--secondary-color);
    cursor: grabbing;
}
.rc-slider-handle:hover{
    border-color: var(--secondary-color);
}
.rc-slider-handle{
    border: 2px solid var(--secondary-color);
}

.rc-slider-handle-dragging .rc-slider-handle-dragging .rc-slider-handle-dragging{
    border-color: var(--secondary-color)!important;
    box-shadow: 0 0 5px var(--secondary-color)!important;
}