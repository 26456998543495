/* .admin-student-list-container1 .student-list {
  border: 0.5px solid #cccccc;
  padding: 10px;
  font-size: 14px;
  width: 98%;
  display: inline-flexbox;
  cursor: pointer;
  margin-left: 20px;
  background: white;
}
.admin-student-list-container1 .student-list-active {
  background-color: #e4e4e4;
}
.admin-student-list-container1 .student-list:hover {
  background: #cccccc;
} */

/* .admin-student-list-container .search-student-input{
  border-radius: 5px;
  padding: 5px 15px;
  border: 1px solid black;
  outline: none;
  width: 480px;
} */

/* .admin-student-list-container .student-list {
  border: 0.5px solid #cccccc;
  padding: 10px;
  font-size: 14px;
  width: 400px;
  cursor: pointer;
  margin-left: 20px;
  background: white;
}
.admin-student-list-container .student-list-active {
  background-color: #e4e4e4;
}
.admin-student-list-container .student-list:hover {
  background: #cccccc;
} */


.admin-student-list-container .student-list {
  border: 0.5px solid #cccccc;
  padding: 10px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap; 
  cursor: pointer;
  margin-left: 20px;
  background: white;
  margin-bottom: 10px; 
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px; 
}

.checkbox-group span {
  margin-right: 20px; 
}

.info-group {
  width: 100%; 
}

.admin-student-list-container .student-list-active {
  background-color: #e4e4e4;
}

.admin-student-list-container .student-list:hover {
  background: #cccccc;
}