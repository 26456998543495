.title-container{
    color: var(--selected-primary-button-color);
    padding:5px 15px;
    background-color: var(--background-color);
    border-bottom: 3px solid var(--selected-primary-button-color);
}
.campaign-containers{
    background-color: var(--background-color);
    height: 100%;
    padding:30px 30px 100px 30px;
}
.campaign-wrapper{
    border: 2px solid var(--secondary-color);
    background-color: white;
    height: 100%;
    padding: 0px 15px;
}
.campaign-grid-container{
    display: grid;
    grid-template-columns: 1.8fr 1.2fr;
    grid-column-gap:40px;
    height: 100%;
}
.single-campaign{
    box-shadow: 8px 8px 10px #ece8dd;
    justify-content: space-between;
    padding: 16px 30px;
    border: 1px solid #ece8dd;
    border-bottom: 3px solid var(--selected-primary-button-color);
    height: 75px;
    margin:15px 0px
}
.campaign-text{
    font-size: 18px;
    font-weight: 700;
    padding:5px 0px
}
.campaign-icons{
    margin:0px 10px;
}
.campaign-separator{
    border-left: 2px solid #ece8dd;
}
.campaign-header{
    background-color: var(--background-color);
    margin-top: 10px;
    padding: 5px 15px;
    height: 60px;
    line-height: 50px;
    border-bottom: 3px solid black;
    display: flex;
    flex-direction: column;
}
.campaign-input{
    width: 100%;
    height:45px;
    padding:15px
}
.campaign-input::placeholder{
    color:  darkgray;
    font-size: 14px;
    font-weight: 700;
}
.campaign-input:focus{
    border: 1px solid rgb(102 175 233);
    outline: none;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}
.use-result-table{
    height: 45px;
    width:100%
}
.date-picker{
    width:125px
}
.date-container{
    width:180px;
    margin-left: 5px;
}
.campaign-button{
    height: 45px;
    width: 180px;
    line-height: 40px;
    background-color: transparent;
    font-weight: 700;
}
.active-color{
    background-color: var(--selected-primary-button-color);
    color: white;
}
.inactive-color{
    background-color: white;
    color: var(--selected-primary-button-color);
}
.less-opacity{
    opacity: 0.8;
}