.add-student-list-container{
    width:85%;
    border: 1px solid #eee;
    max-height:200px;
    overflow-y: scroll;
    margin-top: -25px;
}
.add-group-student-list{
    width:100%;
    padding:3px 5px
}
.add-group-student-list:hover{
    background-color: #337ab7;
    color: white;
}
.alert-danger{
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding:10px;
    border-radius: 5px;
}