.error-class {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
  font-size: 14px;
  margin-top: 15px;
  width: 70%;
}
.success-class {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  padding: 15px;
  font-size: 14px;
  margin-top: 15px;
  width: 70%;
}
