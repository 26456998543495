.current-student-header{
    background-color: var(--background-color);
    border: 2px solid #333333;
    padding:7px 25px
}
.student-current{
    font-size: 17px;
    font-weight: 700;
    color: var(--title-text-color);
    margin-bottom: 0;
    padding: 5px 0 0 30px;
    cursor: auto;
}
.current-student-tabs-container{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 5px;
}
.current-student-container{
    background: var(--background-color);
    position:relative;
    width: 100%;
    padding: 15px 30px; 
}
.current-student-search-input{
    width:40%;
}
.student-list-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transition: transform 0.2s ease;
}

@media screen and (max-width:769px) {
    .current-student-tabs-container{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 5px;
    }  
    .current-student-container{
        padding: 15px;
        height: auto;
    }  
    .current-student-search-input{
        width:100%;
    }
    .current-student-total-student{
        margin: auto;
    }
}