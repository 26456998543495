.resetPswdWrap{
    height: 100vh;
    .logoImg {
        margin-top: 45px;
        margin-bottom: 40px;
        width: 180px;
    }

    .resetPswdInner {
        background: url('https://siembra-frontend.s3.us-west-1.amazonaws.com/images/forgot-pswd-bg.png') no-repeat center;
        background-size: cover;
        // height: 395px;
        height: 48vh;
    }
    
    .resetPswdBlockInner {
        .boxWrap {
            max-width: 745px;
            margin-left: auto;
            margin-right: auto;
        }

        .boxwrapInner {
            max-width: 745px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 30px;
            padding: 45px 80px 32px 80px;

            background: var(--white-color);
            border: 1px solid rgba(138, 165, 182, 0.2);
            box-shadow: 0px 8px 8px rgba(141, 161, 172, 0.15);
            border-radius: 15px;
            
            @media(max-width: 767px){
                padding: 45px 15px 32px 15px;
            }

            .accountTitle {
                margin-bottom: 8px;
                font-weight: var(--font-bold);
                font-size: var(--font24);
                line-height: 1.2;
                letter-spacing: -0.3px;
                color: var(--primary-font-color);
            }
            p {
                margin-bottom: 60px;
                font-weight: var(--font-normal);
                font-size: var(--font20);
                line-height: 1.2;
                letter-spacing: -0.2px;
                color: var(--secondary-font-color);
            }
        }
    }
}