// Variables

$spacer-lg                      : 3rem;
$spacer-sm                      : 1.5rem;

$layout-spacer-lg               : 3rem;
$layout-spacer-x                : 1.5rem;

$app-sidebar-width              : 270px;
$app-sidebar-width-collapsed    : 80px;

$logo-height                    : 41px;
$logo-width                     : 100px;

$app-header-height              : 60px;