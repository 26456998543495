.single-messageData-container{
    border-top: 2px solid var(--text-color);
    margin: 50px 15px 15px 0px;
    background-color: var(--background-color);
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-left: 2px solid var(--text-color);
    height: 100%;
}
.singlemessage{
    box-shadow:0 0 10px 0 rgb(0 0 0 / 20%);
    height:200px;
    overflow-y: scroll;
}
.single-message-group-container{
    height:500px;
    overflow-y: scroll;
}
.date-time-delete-container{
    display: flex;
    padding:10px
}
.date-time-delete-container img{
    width:15px;
    height: 15px;
    margin-right: 15px;
    cursor: pointer;
}
.single-message-wrapper{
    display: flex;
    justify-content: flex-end;
    padding:15px
}
.single-message-wrapper img{
    width:50px;
    height:50px;
    border-radius: 25px;
}
.single-message-wrapper p{
    margin-bottom: 15px;
    text-align: end;
    font-weight: bold;
    font-size: 14px;
}
.choose-template-container{
    height:65px;
    background-color: var(--selected-primary-button-color);
    color: var(--input-label-color);
    font-size: 18px;
    line-height: 65px;
    padding: 0 15px;
}
.type_a_reply{
    width:100%
}
.reply_container{
    background-color: var(--selected-primary-button-color);
    margin-top: -8px;
    height: 76px;
    color: var(--input-label-color);
}

@media screen and (max-width:769px){
.single-messageData-container{
    border: 1px solid var(--text-color);
}
.reply_container{
    height: auto;
}
}